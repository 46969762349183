import React from 'react';
import MuiLink from '@material-ui/core/Link';
import { Link as GatsbyLink } from 'gatsby';

export const LinkIn = React.forwardRef(function Link(props, ref) {
  return <MuiLink component={GatsbyLink} ref={ref} {...props} />;
});

export const LinkOut = React.forwardRef(function Link(props, ref) {
  return <MuiLink ref={ref} {...props} />;
});

export const LinkTab = React.forwardRef(function Link(props, ref) {
  return <MuiLink target="_blank" rel="noopener" ref={ref} {...props} />;
});